import Img from 'gatsby-image';
import * as React from 'react';
import styled from 'styled-components';
import { Container, Divider, Icon } from 'semantic-ui-react';
import { LayoutProps, menuItems } from '../Layout';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import { Link } from 'gatsby';
import { useState } from 'react';
import { SyntheticEvent } from 'react';

interface TechProps {
  color: string;
  name: string;
}

export interface TimelineBlockProps {
  img: any;
  anchor: string;
  date: string;
  left?: boolean;
  index: number;
  role: string;
  tech: TechProps[];
  text: string[];
  title: string;
}

const TimelineBlock = styled('div')`
  text-align: left;
  //background-color: #000 !important;
  //height: 200px;
  //margin-bottom: 1rem;
`;

const Text = styled('p')`
  font-size: 1.2rem !important;
`;

const Technology = styled('span')`
  margin: 0 0.5rem 0.5rem 0 !important; 
`;

export default (props: TimelineBlockProps) => {
  return (
    <TimelineBlock className={`cd-timeline-block`}>
      <div className={`cd-timeline-img`}>
        <Img fluid={props.img} className={'img'} />
      </div>
      <div className={`cd-timeline-content`}>
        <h2 className="ui header">
          {props.title}
          <div className="sub header">{props.date}, {props.role}</div>
        </h2>
        <div className="ui list">
          {props.tech.map((t, i) => (
            <Technology key={`key-${i}`} className={`ui label ${t.color}`}>
              {t.name}
            </Technology>
          ))}
        </div>
        {props.text.map((t, i) => (
          <Text key={`p-${i}`} className={`ui large`}>
            {t}
          </Text>
        ))}
        {/*<span className="cd-date">{props.date}</span>*/}
      </div>
    </TimelineBlock>
  );
};

import * as React from 'react';
import styled from 'styled-components';
import { LayoutProps } from '../Layout';
import TimelineBlock from './TimelineBlock';

import data from './TimelineData';

const Timeline = styled('section')``;

const names = [
  'acyc',
  'timeytim',
  'kindur',
  'bloomberg',
  'izuded',
  'hyr',
  'foghorn',
  'fiftythree',
  'healthyhand',
  'hotlist',
];

export default (props: LayoutProps) => (
  <Timeline id={`cd-timeline`} className={`cd-container`}>
    {names.map((n, i) => (
      <TimelineBlock
        anchor={n}
        key={`block-${i}`}
        {...data(props, n)}
        left={Boolean(i % 2)}
      />
    ))}
  </Timeline>
);

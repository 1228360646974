import Img from 'gatsby-image';
import * as React from 'react';
import styled from 'styled-components';
import { Container, Segment } from 'semantic-ui-react';
import { LayoutProps, menuItems } from '../Layout';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import { Link } from 'gatsby';

const Masthead = styled(Segment)`
  background-color: #000 !important;
  max-height: 130px;
  //margin-bottom: 1rem;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .hovering {
    animation: fadein 3s;
    //visibility: hidden;
  }
  &:hover {
    .hovering {
      //visibility: visible;
    }
  }
`;
const FadeIn = styled('div')`
  #container + div {
    transition: opacity 0.25s;
    opacity: 0;
  }

  #container:hover + div {
    opacity: 1;
  }
`;
const Glasses = styled(Img)`
  width: 200px;
  margin: -40px auto 0 0%;
  //width: 160px;
  //margin: 0 auto;
  //margin-top: -40px;
  //margin-left: 0;
`;

export default (props: LayoutProps) => (
  <FadeIn id={`container`}>
    <Masthead vertical textAlign="center" className="masthead">
      <HeaderMenu
        Link={Link}
        pathname={props.location.pathname}
        items={menuItems}
      />
      <Container text textAlign={'center'}>
        <Glasses
          alt={`3-D`}
          fadeIn={false}
          fluid={props.data.shadowGlasses.childImageSharp.fluid}
        />
      </Container>
    </Masthead>
  </FadeIn>
);

import Img from 'gatsby-image';
import * as React from 'react';
import styled from 'styled-components';
import { Container, Segment } from 'semantic-ui-react';
import { LayoutProps } from '../Layout';

const SummaryContainer = styled(Container)`
  padding: 1.6rem 0 2rem;
`;
const Summary = styled(Segment)`
  margin: 0 auto !important;
  border: 0 !important;
  max-width: 800px;
`;

export default (props: LayoutProps) => (
  <SummaryContainer>
    <Summary vertical>
      <div className="ui two column stackable grid">
        <div className="eight wide column" style={{ textAlign: 'left' }}>
          <h2 className="ui header">
            <Img
              title={`u don' wanna pisa dis`}
              fadeIn={false}
              style={{ width: `120px`, margin: `0 1rem 0 auto` }}
              fluid={props.data.mk.childImageSharp.fluid}
              className={'ui circular image'}
            />

            <div className="content" style={{ textAlign: 'left' }}>
              Michael Kane
              <div className="sub header">
                <p>Computer Wizard</p>
              </div>
              <div className="small sub header">
                <a title="Email me!" href="mailto:mk@mk4p.com">
                  mk@mk4p.com
                </a>
              </div>
            </div>
          </h2>
        </div>
        <div
          className="eight wide column"
          style={{
            fontSize: '1rem',
            paddingRight: 0,
            paddingTop: '2rem',
            textAlign: 'left',
          }}
        >
          <div className="cli-bio">
            <span>I've worked on startups since 1998.</span>
            <span>I use TypeScript, React, Node,</span>
            <span>AWS, and "serverless" architectures.</span>
            <span>I'm a product development nut.</span>
          </div>
        </div>
      </div>
    </Summary>
  </SummaryContainer>
);

import * as React from 'react';
import { LayoutProps } from '../Layout';

export default (props: LayoutProps) => (
  <div className={`ui grid`} style={{ margin: `2rem 0` }}>
    <div className={`row`}>
      <div className={`center aligned column`}>
        <h4 className={`ui header`} style={{fontSize: '1.4rem'}}>Want more?</h4>
        <p style={{fontSize: '1.2rem'}}>
          <a title="Email me!" href="mailto:mk@mk4p.com">
            Email me
          </a>{' '}
          for sample code, additional examples, or to discuss your next project!
        </p>
      </div>
    </div>
  </div>
);

import { LayoutProps } from "../Layout";

export default (props: LayoutProps) => [
  {
    header: `I've had a wonderful experience working with Michael.`,
    img: props.data.chrisMirabile.childImageSharp.fluid,
    href: "https://www.linkedin.com/in/chrismirabile/",
    person: "Chris Mirabile",
    text: [
      `I can highlight that he's an exceptionally skilled developer, but that wouldn't do him justice. Where Michael stands out is in his "soft" skills: his personality and character. Michael is among the most loyal, hardworking, persevering, personable, and empathetic people I have had the pleasure to know and the privilege to work with, and to find such a combination in such a strong developer is a rarity.`,
    ],
    title: `Serial Entrepreneur`,
  },
  {
    header: `Michael is the epitome of a team player.`,
    img: props.data.bethanneZink.childImageSharp.fluid,
    href: "https://www.linkedin.com/in/bethanne-zink/",
    person: "Bethanne Zink",
    text: [
      `His addition to our team brought forth an immediate shift in dynamic, increasing collaborative ideation and information-sharing, as well as enhancing our general team camaraderie. He offers suggestions readily and in an egoless manner — truly attempting to make the team better — and encourages others to do the same, regardless of their experience level. He’s an impressive and knowledgeable engineer, and he’s also humble and eager to learn from anyone.`,
      `He helps create an environment of learning — formally sharing presentations with the team on newly-implemented tools (ex: Gatsby & Netlify), while also eagerly sharing his time to sit with folks and work through hairy problems or help set up time-saving tools. He encourages clean code practices and keeps up-to-date on modern trends — he’s excited to discover new and useful things, but is intelligent about settling on sustainable approaches. He’s kind, communicative, and just so very helpful.`,
      `Also, he absolutely loves building things. He’s an engineer who delights in his work and his delight rubs off on others, bringing laughter to stressful days and making the work environment exciting and fun.`,
      `I’ve learned so much from Michael, and I aim to be more like him, particularly in the humility, delight, and curiosity that he brings to each endeavor.`,
    ],
    title: `Software Engineer at Feather`,
  },
  {
    header: `Michael is the most skilled and technically proficient UI developer I've ever worked with.`,
    img: props.data.daveStern.childImageSharp.fluid,
    href: "https://www.linkedin.com/in/sterndave",
    person: "Dave Stern",
    text: [
      `He has a comprehensive knowledge of the latest front-end tools, techniques and libraries.
          He constantly seems to be learning and improving the systems he builds, writes
          understandable documentation and works seamlessly with back-end developers
          — I would not hesitate to
          work with him again as I don't think I could find anyone better.`,
    ],
    title: `DevOps Engineer & Consulting CTO`,
  },
  {
    header: `Few things are as valuable to a JavaScript developer as mastery of the language.`,
    img: props.data.cameronMoss.childImageSharp.fluid,
    href: "https://www.linkedin.com/in/cameron-moss-46a18779",
    person: "Cameron Moss",
    text: [
      `On more than a few occasions, Michael has rescued me from a predicament arising
      from my naiveté on the subject.`,
      `Michael has fantastic wit and humor. It is a pleasure to work with him.
      What strikes me about his work is how complete it is on delivery.
      Michael takes care to cover every detail in realizing a goal.
      His management of AWS resources would prove invaluable to an organization
      looking to utilize all of even their most obscure services.`,
    ],
    title: `Senior Software Engineer at Talkspace`,
  },
];

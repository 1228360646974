import Img from 'gatsby-image';
import * as React from 'react';
import { SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import { Card, CardProps, Divider, Grid, Icon } from 'semantic-ui-react';

import { LayoutProps } from '../Layout';

import content from './RecommendationData';

const RecommendationsGrid = styled(Grid)`
  margin: 1.6rem 0 2rem !important;
`;
const RecommendationsRow = styled(Grid.Row)`
  margin: 0 auto !important;
  border: 0 !important;
  max-width: 800px;
`;
const RecommendationCard = styled(Card)`
  text-align: left;
  box-shadow: none !important;
  font-size: 1.3rem;
`;
const Description = styled('a')`
  cursor: pointer;
  color: #707070 !important;
  display: block;
  transition: color 0.75s linear;
  &:hover {
    color: #505050 !important;
  }
  &.open {
    color: #000 !important;
  }
  p {
    line-height: 2rem;
    :last-of-type {
      display: inline;
    }
  }
`;
const Rdquo = styled('span')`
  :after {
    content: '”';
  }
`;
const Speaker = styled('div')`
  padding: 0.5rem 0;
`;

interface RecommendationProps extends CardProps {
  header: string;
  href: string;
  img: any;
  person: string;
  text: string[];
  title: string;
}

const getText = (text: string[], open: boolean): string[] =>
  open ? text : [text[0].split(' ').slice(0, 13).join(' ') + '...'];

export const Recommendation = (props: RecommendationProps) => {
  const [open, setOpen] = useState(true);
  return (
    <RecommendationCard fluid className={`ui huge recommendation`}>
      <div className="content">
        <a href={props.href} target={`_blank`} title={props.person}>
          <Img
            fadeIn={false}
            fluid={props.img}
            alt={`Photo of ${props.person}`}
            className={`ui tiny avatar image floated left`}
            style={{ marginBottom: `1rem` }}
          />
        </a>
        <div className="header">
          &ldquo;{props.header}
          <Speaker>
            <a
              className={`author`}
              href={props.href}
              target={`_blank`}
              title={props.person}
            >
              {props.person}, {props.title}
            </a>
          </Speaker>
        </div>
        <Description
          className={`description ${(open && 'open') || ''}`}
          onClick={(_e: SyntheticEvent) => setOpen(!open)}
        >
          {getText(props.text, open).map((t: string, i: number) => (
            <p key={i}>{t}</p>
          ))}
          <Rdquo />
          <Divider horizontal>
            <Icon color={`blue`} name={`chevron ${open ? 'up' : 'down'}`} />
          </Divider>
        </Description>
      </div>
    </RecommendationCard>
  );
};

export default (props: LayoutProps) => {
  return (
    <RecommendationsGrid stackable>
      <RecommendationsRow>
        <Grid.Column width={16}>
          <Recommendation fluid basic {...content(props)[0]} />
        </Grid.Column>
      </RecommendationsRow>
      <RecommendationsRow>
        <Grid.Column width={16}>
          <Recommendation fluid basic {...content(props)[1]} />
        </Grid.Column>
      </RecommendationsRow>
      <RecommendationsRow>
        <Grid.Column width={16}>
          <Recommendation fluid basic {...content(props)[2]} />
        </Grid.Column>
      </RecommendationsRow>
      <RecommendationsRow>
        <Grid.Column width={16}>
          <Recommendation fluid basic {...content(props)[3]} />
        </Grid.Column>
      </RecommendationsRow>
    </RecommendationsGrid>
  );
};

import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import HeaderMenu from '../components/HeaderMenu/HeaderMenu';
import { withLayout, LayoutProps, menuItems } from '../components/Layout';
import styled from 'styled-components';
import { Container, Divider } from 'semantic-ui-react';

import Intro from '../components/Home/Intro';
import Masthead from '../components/Home/Masthead';
import Recommendations from '../components/Home/Recommendations';
import Timeline from '../components/Experience/Timeline';
import WelcomeLetter from '../components/Home/WelcomeLetter';
import Helmet from 'react-helmet';
import WantMore from "../components/Home/WantMore";

const StyledDivider = styled(Divider)`
  color: #999 !important;
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
`;

const IndexPage = (props: LayoutProps) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Helmet>
        <meta name="referrer" content="no-referrer" />
      </Helmet>
      <Masthead {...props} />
      <Intro {...props} />
      <WelcomeLetter {...props} />
      <Container>
        {/*<StyledDivider horizontal>Recommendations</StyledDivider>*/}
        <Recommendations {...props} />
      </Container>
      <Container>
        <StyledDivider horizontal>Experience</StyledDivider>
        <Timeline {...props} />
      </Container>
      <Container>
        <Divider />
      </Container>
      <Container>
        <WantMore />
      </Container>
      <div
        className="ui inverted fluid three item menu"
        style={{ position: 'fixed', bottom: 0, borderRadius: 0, zIndex: 100 }}
      >
        <div className="item">&nbsp;</div>
        <div className="item">
          <a
            className="ui huge inverted labeled icon button"
            href="mailto:mk@mk4p.com"
          >
            <i className="mail icon" />
            mk@mk4p.com
          </a>
        </div>
        <div className="item">&nbsp;</div>
      </div>
    </div>
  );
};

export const query = graphql`
  {
    acyc: file(relativePath: { eq: "img/projects/acyc.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bloomberg: file(relativePath: { eq: "img/projects/bloomberg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cdre: file(relativePath: { eq: "img/projects/cdre.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    fiftythree: file(relativePath: { eq: "img/projects/fiftythree.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    foghorn: file(relativePath: { eq: "img/projects/foghorn.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    healthyhand: file(relativePath: { eq: "img/projects/healthyhand.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hotlist: file(relativePath: { eq: "img/projects/hotlist.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hyr: file(relativePath: { eq: "img/projects/hyr.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    izuded: file(relativePath: { eq: "img/projects/izuded.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    kindur: file(relativePath: { eq: "img/projects/kindur.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    livekick: file(relativePath: { eq: "img/projects/livekick.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    timeytim: file(relativePath: { eq: "img/projects/timeytim.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    glasses: file(relativePath: { eq: "img/3dglasses.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    isoGlasses: file(relativePath: { eq: "img/iso-3d-glasses.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    shadowGlasses: file(relativePath: { eq: "img/iso-3d-glasses.shadow.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mk: file(relativePath: { eq: "img/mk.697.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bethanneZink: file(relativePath: { eq: "img/bethanne-zink.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cameronMoss: file(relativePath: { eq: "img/cameron-moss.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    chrisMirabile: file(relativePath: { eq: "img/chris-mirabile.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    daveStern: file(relativePath: { eq: "img/dave-stern.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    signature: file(relativePath: { eq: "img/signature.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default withLayout(IndexPage);

import * as React from 'react';
import Img from 'gatsby-image';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';
import { LayoutProps } from '../Layout';
import {useEffect} from "react";

const Item = styled(List.Item)`
  line-height: 2.3rem !important;
`;

const Signature = styled(Img)`
  max-width: 100px;
  margin-left: 0;
`;

export default (props: LayoutProps) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log(rocker);
      console.log('Ayyyyyyyyyyyyy');
    }, 3000);

    return () => clearTimeout(timeout);
  }, [])
  return (
    <div
      className="ui huge message container"
      style={{
        margin: '1rem 0 3rem 0',
        border: 0,
        textAlign: 'left',
      }}
    >
      <h2 style={{ color: '#505050' }}>Is your dev load overwhelming?</h2>

      <p>I know the feeling.</p>

      <p>
        I've been there many times, in many startups, and have learned some
        important lessons:
      </p>

      <List bulleted relaxed>
        <Item>
          First, ensuring your next steps are necessary. Many times they're not,
          and it's far better to discover this during the planning stages.
        </Item>
        <Item>
          Second, test-driven development! Moving fast isn't worth the exposure
          of poorly-tested code.
        </Item>
        <Item>
          Finally, it's a marathon, not a sprint. Measured steps beat
          all-nighters, and they're endlessly repeatable.
        </Item>
      </List>

      <p>
        I love code, I love developing products, and I love working with cool,
        smart, funny people.
      </p>

      <p>Thanks for visiting!</p>

      <div>
        <div style={{ maxWidth: `100px`, width: `100px` }}>
          <Signature fluid={props.data.signature.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  );
};

const rocker = `
_________▓▓▒▓▓▒▓▓
________▓▓▓▒▓▓▒▓▓▓
______▓▓▓▓▒▓▒▒▓▒▓▓▓
_____▓▓▓▓▓▓▓▓▓▓▓▓▓▓
____▓▓▓▓▓▓______▓▓▓▓▓
____▓▓▓▓▓__█__█__▓▓▓▓
_____▓▓▓▓____--____▓▓▓
____▓▓▓▓▓____▄___▓▓▓▓
___▓▓▓▓▓▓▓______▓▓▓▓
____▓▓▓▓▓▓▓▓●___●▓▓
___██████●█▒இ▒█●██
__██████●█▒♣இ♣▒█●█
__███●█▒▒♣இ♣▒▒█●██
___██████●█▒♣இ♣▒█●█
____█████●█▒♣இ♣▒█●█
_____█████●█▒♣இ▒█●█
______▒████●█▒இ▒█●█▒
______▒▒_███●█இ█●▓█_▒▒
_______▒▒__█_█●█●▓██_▒▒
_______▒▒__████●═●██_▒▒
________█████████████
_________████████████
_________████████████
__________████████████
___________████████████
___________██████_██████
___________██████__██████
___________██████___█████
___________██████___█████
___________██████___████
___________████████████
___________███████████
_____▓▓▓▓▓██████████
____▓▓▓████████
__▓▓▓█████████
_▓▓▓▓____██████
__▓▓______█████
___▓______██████
__________▓▓_▓▓▓▓
__________▓____▓▓▓▓▓
`;

import React from 'react';
import { Icon } from 'semantic-ui-react';

const ExternalLink = (props: { text: string; href: string }) => (
  <a href={props.href} target={`_blank`} title={props.text}>
    {props.text} <Icon size={`small`} name={`external`} color={`grey`} />
  </a>
);

// eslint-disable-next-line @typescript-eslint/no-angle-bracket-type-assertion
export default (props: any, name: string) => {
  // @ts-ignore
  const output = data[name];
  output.img = props.data[name].childImageSharp.fluid;
  return output;
};

const tech = {
  angular: { name: 'Angular', color: 'green' },
  aws: { name: 'AWS', color: 'violet' },
  csharp: { name: 'C#', color: 'purple' },
  docker: { name: 'Docker', color: 'black' },
  gae: { name: 'Google App Engine', color: 'black' },
  gatsby: { name: 'Gatsby', color: 'orange' },
  gcp: { name: 'Google Cloud', color: 'purple' },
  jquery: { name: 'jQuery', color: 'orange' },
  mongo: { name: 'MongoDB', color: 'brown' },
  node: { name: 'NodeJS', color: 'pink' },
  postgres: { name: 'Postgres', color: 'purple' },
  python: { name: 'Python', color: 'teal' },
  react: { name: 'React', color: 'blue' },
  sls: { name: 'Serverless', color: 'black' },
  typescript: { name: 'TypeScript', color: 'green' },
  vanilla: { name: 'Vanilla JS', color: 'green' },
  wip: { name: 'Under construction', color: '' },
};

export const data = {
  acyc: {
    title: (
      <React.Fragment>
        <ExternalLink
          href={'https://www.consortiumkey.com/'}
          text={`Consortium Key`}
        />
      </React.Fragment>
    ),
    role: 'Technical Contributor',
    date: '2021-2022',
    text: [
      <React.Fragment>
        Consortium Key is a crypto hedge fund and suite of tools for trading altcoins.
      </React.Fragment>,
      <React.Fragment>
        As a contributor to the project, I've helped implement the React front end as well as Discord
        bots that follow trading activity on the Ethereum blockchain.
      </React.Fragment>,
    ],
    tech: [tech.react, tech.node, tech.typescript],
  },
  bloomberg: {
    title: (
      <React.Fragment>
        <ExternalLink
          href={'https://eap.bloomberg.com'}
          text={`Bloomberg Enterprise Access Point`}
        />
      </React.Fragment>
    ),
    role: 'Senior Software Engineer',
    date: '2017-2019',
    text: [
      <React.Fragment>
        Financial data from Bloomberg L.P. has traditionally been served via the{' '}
        <a
          href="https://en.wikipedia.org/wiki/Bloomberg_Terminal"
          target="_blank"
        >
          Bloomberg Terminal
        </a>
        , a fixture in the offices of investment firms worldwide.
      </React.Fragment>,
      <React.Fragment>
        In order to serve customers seeking to process data using their own
        in-house systems, Bloomberg launched the{' '}
        <a href="https://eap.bloomberg.com/" target="_blank">
          Enterprise Access Point
        </a>{' '}
        in March 2018. Through the Enterprise Access Point, customers can browse
        datasets and purchase packages of bulk financial data to import through
        an API.
      </React.Fragment>,
      <React.Fragment>
        In my first year at Bloomberg, I was one of the three developers working
        on the user-facing components the site, using Express.js on the server
        and React on the front end. Never before had I worked on a web
        application as complex and thoroughly-tested, utilizing Jest, Mocha,
        Nightwatch.js, and Jenkins servers for continuous integration.
      </React.Fragment>,
    ],
    tech: [tech.docker, tech.react, tech.node],
  },
  fiftythree: {
    title: (
      <React.Fragment>
        <ExternalLink href={'https://fiftythree.com'} text={`FiftyThree`} />
      </React.Fragment>
    ),
    role: 'Full-Stack Developer',
    date: '2015',
    text: [
      <React.Fragment>
        <a
          title="FiftyThree's web site"
          href="https://www.fiftythree.com"
          target="_blank"
        >
          FiftyThree
        </a>{' '}
        (on{' '}
        <a
          title="FiftyThree on CrunchBase"
          href="https://www.crunchbase.com/organization/fiftythree"
          target="_blank"
        >
          CrunchBase
        </a>
        ) has a social network called Mix based on the artwork their users
        create using their app,&nbsp;
        <a
          title="FiftyThree's iOS app, Paper"
          href="http://app.appsflyer.com/id506003812?pid=fiftythree.com-paper&c=paper-3-launch"
          target="_blank"
        >
          Paper
        </a>
        .
      </React.Fragment>,
      <React.Fragment>
        They needed tools to manage this content. They provided the APIs and I
        built the interface using React, Fluxxor and CJSX. It's private, but
        here's a screencast describing it:
      </React.Fragment>,
      <React.Fragment>
        <iframe
          width="420"
          height="315"
          src="https://www.youtube.com/embed/0TwjnTIPTi4?start=102"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </React.Fragment>,
      <React.Fragment>
        This team writes excellent code and taught me lessons like the priceless
        "code you don't write doesn't break."
      </React.Fragment>,
    ],
    tech: [tech.react, tech.node, tech.aws],
  },
  foghorn: {
    title: 'Foghorn',
    role: 'Co-Founder',
    date: '2015 - 2016',
    text: [
      <React.Fragment>Emergency phone numbers for families.</React.Fragment>,
      <React.Fragment>
        Subscribers receive a personal phone number for easily reaching
        emergency contacts.
      </React.Fragment>,
      <React.Fragment>
        Depending on the configuration of the Foghorn number, incoming calls are
        routed to trusted contacts in tiers, with each incident generating
        phone, email, and sms notifications.
      </React.Fragment>,
      <React.Fragment>
        Ad-hoc mailing lists serve to keep people informed while they resolve
        each incident.
      </React.Fragment>,
      <React.Fragment>
        From the backend to the front, I'm using Heroku, Postgres, AWS SQS,
        CoffeeScript, Fluxxor and React. My favorite aspect is the integration
        with Codeship to perform continuous delivery.
      </React.Fragment>,
      <React.Fragment>
        Foghorn will be live{' '}
        <span
          style={{
            textDecoration: 'line-through',
            color: '#505050',
          }}
        >
          Q1 2017
        </span>{' '}
        edit: since taking on full-time employment, I've had to put this project
        on the back burner, but I still love it and intend to launch! (Just as
        soon as I have the time...).
      </React.Fragment>,
      <React.Fragment>
        This screencast shows a bit of the functionality:
      </React.Fragment>,
      <React.Fragment>
        <iframe
          width="420"
          height="315"
          src="https://www.youtube.com/embed/SW-d-E6kHSc"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </React.Fragment>,
    ],
    tech: [tech.react, tech.node, tech.postgres, tech.aws, tech.wip],
  },
  healthyhand: {
    title: 'Healthy Hand',
    role: 'Co-Founder',
    date: '2013-2014',
    text: [
      <React.Fragment>
        Healthy Hand (defunct,{' '}
        <a
          title="Healthy Hand featured on Vogue's web site"
          href="http://www.vogue.com/867340/a-movable-feast-fashion-weeks-best-anytime-anywhere-meals/#74403202999880"
          target="_blank"
        >
          Vogue
        </a>
        ,{' '}
        <a
          title="Healthy Hand featured on NY Daily News' web site"
          href="http://www.nydailynews.com/life-style/eats/food-delivery-services-serving-specialized-niches-new-york-article-1.1815795"
          target="_blank"
        >
          NY Daily News
        </a>
        ) provided healthy meal delivery by combining dietitian-curated meals
        served by local delivery restaurants.
      </React.Fragment>,
      <React.Fragment>
        In addition to various co-founder responsibilities, I built the
        administrative interface, multiple iterations of our consumer-facing
        product, and managed 3 outside developers. With user-specific dietary
        settings, our proprietary dietetic database, and GrubHub's API, we
        generated emails containing healthy meal options for our subscribers.
      </React.Fragment>,
      <React.Fragment>
        Clicking a healthy meal option would then place the order through
        GrubHub's API so subscribers could stick to their diets without needing
        to plan ahead or face the decision fatigue (and poor dietary habits) of
        typical online ordering.
      </React.Fragment>,
    ],
    tech: [tech.angular, tech.react, tech.node, tech.mongo],
  },
  hotlist: {
    title: (
      <React.Fragment>
        <ExternalLink
          href={'https://www.crunchbase.com/organization/hotlist'}
          text={`Hotlist`}
        />
      </React.Fragment>
    ),
    role: 'Lead UI Developer',
    date: '2010-2012',
    text: [
      <React.Fragment>
        <a
          title="Hotlist on CrunchBase"
          href="https://www.crunchbase.com/organization/hotlist"
          target="_blank"
        >
          Hotlist
        </a>{' '}
        (defunct) was a location-based social media platform that used Facebook
        social graph data to help users make plans and predict the crowds at
        millions of venues worldwide.
      </React.Fragment>,
      <React.Fragment>
        I worked in a tight-knit 3 developer team building Hotlist's web product
        used by hundreds of thousands of users.
      </React.Fragment>,
      <React.Fragment>
        The main challenge was in keeping the UI performant and easy to use
        across multiple browsers in the days before Angular and React.
      </React.Fragment>,
      <React.Fragment>
        One of my favorite contributions was a library that matched the
        jQuery.getJSON() method and improved performance by a factor of 5+ for
        browsers supporting HTML5 Web Workers.
      </React.Fragment>,
      <span className="ui small basic label" style={{ border: 0 }}>
        * Note: Crunchbase lists the wrong 'Michael Kane'.
      </span>,
    ],
    tech: [tech.jquery, tech.python, tech.gae],
  },
  hyr: {
    title: (
      <React.Fragment>
        Hyr
      </React.Fragment>
    ),
    role: 'Consulting Developer',
    date: '2016',
    text: [
      <React.Fragment>
        Hyr
        (on{' '}
        <a
          title="Hyr on CrunchBase"
          href="https://www.crunchbase.com/organization/hyr-2"
          target="_blank"
        >
          CrunchBase
        </a>
        ) is building a marketplace to efficiently connect traditional companies
        - like restaurants and bars - with independent labor to fill hourly paid
        shift work, on demand.
      </React.Fragment>,
      <React.Fragment>
        I helped architect and implement a "serverless" application for the
        startup's prototype.
      </React.Fragment>,
      <React.Fragment>
        In so doing, I touched many AWS tools, including Lambda, CloudSearch,
        Elastic Beanstalk, DynamoDB, RDS, SNS, SQS, and SWF.
      </React.Fragment>,
      <React.Fragment>
        I learned how to divide an application's functionality across disparate
        microservices communicating over SQS, SNS, and HTTPS, and discovered
        rough edges (and workarounds) in the technologies used in this latest
        trend in software development.
      </React.Fragment>,
    ],
    tech: [tech.sls, tech.aws, tech.node],
  },
  izuded: {
    title: (
      <React.Fragment>
        <ExternalLink href={'https://izuded.today'} text={`iz u ded?`} />
      </React.Fragment>
    ),
    role: 'Sole Developer',
    date: '2016',
    text: [
      <React.Fragment>
        iz u ded? is a simple utility with a unique value proposition.
      </React.Fragment>,
      <React.Fragment>
        I adopted a puppy and morbidly realized that, if I were to be hit by a
        bus on a Friday, it might be a few days before anyone knew I was
        unavailable to care for him.
      </React.Fragment>,
      <React.Fragment>
        With a tongue-in-cheek presentation of a serious matter, I started
        building{' '}
        <a href="https://izuded.today" target={'_blank'} title={'iz u ded?'}>
          izuded.today
        </a>
        .
      </React.Fragment>,
      <React.Fragment>
        This application is an exercise in minimalism. It uses no JS frameworks
        (jQuery only), no datastore (except for a few bits of data stored in
        Auth0), and no "servers" (except for AWS S3 & Lambda). With this
        architecture, the initial cost to run the application will be $1 /
        month.
      </React.Fragment>,
    ],
    tech: [tech.sls, tech.aws, tech.node, tech.vanilla],
  },
  kindur: {
    title: (
      <React.Fragment>
        <ExternalLink href={'https://kindur.com'} text={`Kindur`} />
      </React.Fragment>
    ),
    role: 'Lead Front End Engineer',
    date: '2019',
    text: [
      <React.Fragment>
        Kindur (on{' '}
        <a
          href={'https://www.crunchbase.com/organization/kindur'}
          target={'_blank'}
          title={`Kindur on Crunchbase`}
        >
          Crunchbase
        </a>
        ) helps baby boomers manage their money so they can "retire fearlessly".
      </React.Fragment>,
      <React.Fragment>
        In this role, I did work on the NodeJS back end but my primary
        responsibility was in leading the development of our marketing site.
      </React.Fragment>,
      <React.Fragment>
        I researched and selected technologies to improve site performance, set
        up CI/CD pipelines using CircleCI, Gatsby, and Netlify, and designed a
        streamlined architecture that let us move fast (and not break things).
      </React.Fragment>,
      <React.Fragment>
        To increase UI consistency and reduce the workload for the back end
        engineers, I built a shared components library that automatically
        deployed to NPM with each merge to master. This minimized the work
        required to keep our front and back ends in sync, while also allowing us
        to experiment with new semantic versions without any cross-team
        dependencies.
      </React.Fragment>,
      <React.Fragment>
        In addition, I mentored a junior developer and together we implemented a
        design system that enabled us to quickly translate Sketch and Invision
        mockups into functional components.
      </React.Fragment>,
    ],
    tech: [tech.typescript, tech.react, tech.gatsby, tech.gcp, tech.node],
  },
  timeytim: {
    title: (
      <React.Fragment>
        <ExternalLink href={'https://timeytim.com'} text={`TimeyTim`} />
      </React.Fragment>
    ),
    role: 'Founder',
    date: '2020',
    text: [
      <React.Fragment>TimeyTim - Your Scheduling API</React.Fragment>,
      <React.Fragment>
        Once upon a time I built a website that needed scheduling - both one-off
        and recurring events. I had some difficulty dealing with calendars and
        RRules and CRON and knew there could be a better way.
      </React.Fragment>,
      <React.Fragment>
        I've always been interested in time-based projects (see izuded), and
        liked the idea of a low-cost implementation of scheduling functionality
        that could be accessed through an API.
      </React.Fragment>,
      <React.Fragment>
        During the first few months of 2020, I built such a product.
      </React.Fragment>,
      <React.Fragment>
        TimeyTim lets you to schedule Tasks (e.g., "Clean up the database every
        Saturday at 3am"), Monitors ("Alert me if my homepage is missing its
        logo"), and Watchdogs ("Any time the server reboots, let me know if it's
        not back online in 2 minutes").
      </React.Fragment>,
      <React.Fragment>
        The back end is built with AWS Lambda and DynamoDB and the front end is
        built using TypeScript, ReactJS, and Gatsby.
      </React.Fragment>,
    ],
    tech: [tech.aws, tech.sls, tech.typescript, tech.react, tech.gatsby],
  },
};
